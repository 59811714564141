import React from "react";
import RainbowSDKService from "../services/RainbowSDK/RainbowSDKService";
import "./SendMessageBar.css";
import SendButton from "../img/sendButton.svg";
import I18n from "i18n-react";

export default class SendMessageBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "message": "",
        }
        this.handleMessageChange = this.props.handleMessageChange? this.props.handleMessageChange.bind(this) : this.handleMessageChange.bind(this);
        this.buttonAction = this.props.buttonAction? this.props.buttonAction.bind(this) : this.handleButtonSendMessageClicked.bind(this);
        this.handleMessageSend = this.handleMessageSend.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.hasSendTyping = false;
        this.handleButtonSendMessageClicked = this.handleButtonSendMessageClicked.bind(this);
    }

    handleMessageChange(event) {
        this.setState({"message": event.target.value});
        if (event.target.value.length > 0 && !this.hasSendTyping) {
            console.log("ici ", event.target.value)
            RainbowSDKService.rainbowSDK.im.sendIsTypingStateInBubble(this.props.bubble, true);
            this.hasSendTyping = true;
        } else if (event.target.value.length === 0) {
            RainbowSDKService.rainbowSDK.im.sendIsTypingStateInBubble(this.props.bubble, false);
            this.hasSendTyping = false;
            console.log("else ", event.target.value)
        } else {
            console.log("here ", event.target.value)
        }

    }

    handleMessageSend(event) {
        if (this.state.message.length > 0) {
            RainbowSDKService.sendMessageToBubble(this.props.bubble, this.state.message);
            RainbowSDKService.rainbowSDK.im.sendIsTypingStateInBubble(this.props.bubble, false);
            this.setState({"message": ""});
            this.hasSendTyping = false;
            document.getElementById("message-to-send").value = "";
        }
    }

    handleKeyDown(event) {
        console.log("Handle key down : ", event.keyCode);
        if (event.keyCode === 13) {
            this.buttonAction(event);
        }
    }

    handleButtonSendMessageClicked() {
        this.handleMessageSend();
    }

    render() {
        return (
            <div id="send-message-bar" className="send-message-bar">
                <div className="send-message-bar-left"></div>
                <div className="send-message-bar-center">
                    <input
                        id="message-to-send"
                        className="send-message-bar-text"
                        type="text"
                        placeholder={I18n.translate("send_message_bar_input_placeholder")}
                        onChange={this.handleMessageChange}
                        onKeyDown={this.handleKeyDown}
                        disabled={this.props.disabled}
                    />
                </div>
                <div className="send-message-bar-right">
                    <button onClick={this.buttonAction} className="btn-send"><img className="btn-img" src={SendButton} alt={I18n.translate("alt_code_send_message_bar_send_button")}/></button>
                </div>
            </div>
        )
    }
}
