import React from "react";
import { withCookies } from "react-cookie";
import RainbowSDKService from "./services/RainbowSDK/RainbowSDKService";
import WelcomeChat from "./WelcomeChat/WelcomeChat";
import LoadingScreen from "./LoadingScreen/LoadingScreen";
import Config from "./config/config.json";
import "./App.css";
import I18nService from "./services/I18nService";
import I18n from "i18n-react";

const States = {
  Initialising: 'Initialising',
  Initialised: 'Initialised',
  FillingInformationForm: 'FillingInformationForm',
  Authenticating: 'Authenticating',
  Authenticated: 'Authenticated',
  ChatArchived: 'ChatArchived',
  Expired: 'Expired',
  Error: 'Error'
}

class App extends React.Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      appState: States.Initialising,
      room: cookies.get("livechat_room_id") || {}
    }
    console.log("[App] Initialised State : ", this.state);
    this.flow = new URLSearchParams(window.location.search).get("flow");
    this.createSession = this.createSession.bind(this);
    this.joinSession = this.joinSession.bind(this);
    this.initiateChatSession = this.initiateChatSession.bind(this);
    this.joinChatSession = this.joinChatSession.bind(this);
    this.setAppState = this.setAppState.bind(this);
    this.sessionVisibilityChange = this.sessionVisibilityChange.bind(this);

    I18nService.init();
  }

  componentDidMount() {
    let that = this;
    document.addEventListener("visibilitychange", this.sessionVisibilityChange);

    RainbowSDKService.start().then(() => {
      if (Object.keys(that.state.room).length !== 0) {
        that.getSession().then(session => {
          RainbowSDKService.loginWithToken(session.token).then(account => {
            console.log("[App] Account : ", account);
            console.log("[App] Successfully login");
            that.setState({"appState": States.Authenticated});
          }).catch(err => {
            console.log(err);
            const { cookies } = this.props;
            cookies.remove("livechat_room_id");
            this.deleteSession("loginFailed");
            console.log("[App] Cannot login with the token retrieved. Set State to Initialised and try the form step");
            that.setState({"appState": States.Initialised});
          });
        }).catch(err => {
          console.log(err);
          const { cookies } = this.props;
          cookies.remove("livechat_room_id");
          this.deleteSession("sessionFailed");
          that.setState({"appState": States.Initialised, "room": {}});
        });
      } else {
        console.log("[App] Room cookies not found - Show the welcome form");

        that.setState({"appState": States.Initialised});
      }
    }).catch(err => {
      console.log("[App] Error starting the sdk :", err);
      that.setState({"appState": States.Error});
    });
  }

  getSession() {
    return new Promise((resolve, reject) => {
      fetch(Config.chatService.url + "/session?roomId=" + this.state.room.id, {
        method: "get",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
      }).then(response => {
        if (response.status === 200) {
          response.json().then(jsonResponse => {
            resolve(jsonResponse);
          }).catch(err => {
            console.log(err);
            reject("[App] Error while retrieving session : ", err, "We need to create a new one");
          });
        } else {
          reject("[App] Session does not exist. We need to create a new one");
        }
      }).catch(err => {
        reject("[App] Error while getting session => ", err);
      })
    });
  }

  createSession(language, reasonForChat) {
    return new Promise((resolve, reject) => {
      fetch(
          Config.chatService.url +
          "/create-session",
    {
          method: "post",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({language: language, reasonForChat: reasonForChat})
        }
      ).then(response => {
        if (response.status === 200) {
          response.json().then(jsonResponse => {
            resolve(jsonResponse);
          }).catch(err => {
            console.log(err);
            reject(err);
          });
        } else {
          reject("[App] Error creating chat session => ", response);
        }
      }).catch(err => {
        console.log("[App] Error creating chat session => ", err);
        reject(err);
      })
    });
  }

  joinSession(room, flowData) {
    return new Promise((resolve, reject) => {
      fetch(
          Config.chatService.url +
          "/join-session",
          {
            method: "post",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({room: room, userData: flowData})
          }
      ).then(() => {
        resolve();
      }).catch(err => {
        console.log("[App] Error creating chat session => ", err);
        reject(err);
      })
    });
  }

  sessionVisibilityChange(event){
    console.log(event);
    if (Object.keys(this.state.room).length > 0) {
      console.log(document.visibilityState);
      navigator.sendBeacon(Config.chatService.url
          + "/session-visibility-change?roomId="
          + this.state.room.id
          + "&status="
          + document.visibilityState
      )
    }
  }

  async initiateChatSession(language, reasonForChat) {
    try {
      let session = await this.createSession(language, reasonForChat);
      console.log("[App] Successfully got session");
      let account = await RainbowSDKService.loginWithToken(session.token);
      console.log("[App] Account : ", account);
      console.log("[App] Successfully login");
      const { cookies } = this.props;
      // TODO change maxAge to match guestAccount TTL
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        cookies.set("livechat_room_id", session.room, { path: "/", maxAge: 3500, sameSite: 'lax'});
      } else {
        cookies.set("livechat_room_id", session.room, { path: "/", maxAge: 3500, sameSite: 'none', secure: true});
      }
      console.log(session.room);
      this.setState({"room": session.room});
    } catch (e) {
      console.log("[App] Error during initiateChatSession phase: ", e);
      this.setState({"appState": States.Error});
    }
  }

  async joinChatSession(flowData) {
    try {
      let counter = 1;
      while (!(Object.keys(this.state.room).length > 0)) {
        console.log(counter);
        counter += 1;
        await new Promise(res => setTimeout(res, 250));
      }
      await this.joinSession(this.state.room, flowData);
      console.log("[App] Successfully join the session");

      this.setState({"appState": States.Authenticated});
    } catch (e) {
      console.log("[App] Error during joining phase: ", e);
      this.setState({"appState": States.Error});
    }
  }

  deleteSession = async (deletionReason = undefined, reasonForChat = undefined, languageRequested = undefined) => {
    // TODO MOVE THIS IN SERVICE
    let counter = 1;
    while (!(Object.keys(this.state.room).length > 0)) {
      console.log(counter);
      counter += 1;
      await new Promise(res => setTimeout(res, 250));
    }

    fetch(Config.chatService.url + "/delete-session", {
      method: "post",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        roomId: this.state.room.id,
        deletionReason: deletionReason,
        reasonForChat: reasonForChat,
        languageRequested: languageRequested
      })
    }).then(() => {
      console.log("[WelcomeChat] Session has been deleted");
    }).catch(err => {
      console.log("[WelcomeChat] Error trying to delete session => ", err);
    })
  }

  setAppState(state) {
    if (state === States.Error) {
      const { cookies } = this.props;
      cookies.remove("livechat_room_id");
      this.setState({"room": {}});
    }
    this.setState({"appState": state});
  }

  render() {
    return (
      <div className="App">
        <div id="topArea" className="topArea">
          {I18n.translate("chat_header")}
        </div>
        {(() => {
          if (this.state.appState === States.Initialising) {
            return <LoadingScreen message={I18n.translate("loading_screen_livechat_loading")}/>
          } else {
            return <WelcomeChat room={this.state.room} flow={this.flow} appState={this.state.appState} setAppState={this.setAppState} States={States} initiateChatSession={this.initiateChatSession} joinChatSession={this.joinChatSession} deleteSession={this.deleteSession} disabled={true}/>
          }
        })()}
      </div>
    );
  }
}

export default withCookies(App);
