import React from "react";
import Message from "../Message/Message";
import SendMessageBar from "../SendMessageBar/SendMessageBar";
import RainbowSDKService from "../services/RainbowSDK/RainbowSDKService";
import Config from "../config/config.json";
import I18n from "i18n-react";
import I18nService from "../services/I18nService";
import Select from 'react-select';
import { parse } from '../services/UtilsService';
import LegalText from "../LegalText/LegalText";
import Typing from "../Typing/Typing";
import Information from "../Information/Information";
import "./WelcomeChat.css";

export default class WelcomeChat extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            room: this.props.room || {},
            typing: false,
            disabled: this.props.disabled || false,
            chatUnavailabilityRedirection: {
                "isSalesRequestRelated": I18n.translate("regular_chat_unavailability_link"),
                "isSolutionAndProductRelated": I18n.translate("regular_chat_unavailability_link"),
                "isOtherInquiry": I18n.translate("regular_chat_unavailability_link"),
            },
        }
        this.conversationRetrieved = false;
        this.stepsCursor = "1";
        this.chatFlow = this.props.flow === "rainbowOffice"? this.props.flow : "regular";
        this.acceptEnglishChat = undefined;
        this.flowData = {
            language : I18nService.getUsedLanguage(),
            reasonForChat: "",
            firstName: "",
            lastName: "",
            email: "",
            country: "",
            customerType: "",
        };

        this.flowAssociateToReasonForChat = {
            "isSalesRequestRelated": "regular",
            "isSolutionAndProductRelated": "regular",
            "isOtherInquiry": "regular",
        }

        this.stepsCursor = "1";

        this.buttonAction = () => {};

        this.onResize = this.onResize.bind(this);
        this.redirectToContactUs = this.redirectToContactUs.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.unavailableMessage = this.unavailableMessage.bind(this);

        // Regular flow functions
        this.validateRegularPersonalInformation = this.validateRegularPersonalInformation.bind(this);

        // Rainbow office flow functions
        this.validateRainbowOfficeRegularForm = this.validateRainbowOfficeRegularForm.bind(this);
        this.handleSelectCountryChange = this.handleSelectCountryChange.bind(this);
        this.handleRainbowOfficeUserTypeChange = this.handleRainbowOfficeUserTypeChange.bind(this);
        this.validateUserType = this.validateUserType.bind(this);
        this.validateCompanyName = this.validateCompanyName.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.validateOtherCountryChoice = this.validateOtherCountryChoice.bind(this);

        // Shared functions
        this.handleReasonForChatChange = this.handleReasonForChatChange.bind(this);
        this.validateReasonForChat = this.validateReasonForChat.bind(this);
        this.validateEnglishChat = this.validateEnglishChat.bind(this);
        this.handleChangeEnglishChatProposal = this.handleChangeEnglishChatProposal.bind(this);
        this.handlePersonalInformation = this.handlePersonalInformation.bind(this);
        this.stillUnavailableMessage = this.stillUnavailableMessage.bind(this);
        this.isTyping = this.isTyping.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

        // Rainbow SDK Event
        this.isMessageToAdd = this.isMessageToAdd.bind(this);
        this.addRainbowMessageToList = this.addRainbowMessageToList.bind(this);
        this.getConversationMessages = this.getConversationMessages.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.onMessageReceiptReceived = this.onMessageReceiptReceived.bind(this);
        this.onBubbleUpdated = this.onBubbleUpdated.bind(this);
        this.onNewParticipantStatus = this.onNewParticipantStatus.bind(this);

        this.initSteps();
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
        document.addEventListener("keydown", this.handleKeyDown);
        document.addEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWIMMESSAGERECEIVED, this.onMessageReceived);
        document.addEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWIMRECEIPTRECEIVED, this.onMessageReceiptReceived);
        document.addEventListener(RainbowSDKService.rainbowSDK.bubbles.RAINBOW_ONBUBBLEUPDATED, this.onBubbleUpdated);
        document.addEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWPARTICIPANTSTATUS, this.onNewParticipantStatus);
        if (this.props.appState === this.props.States.Initialised) {
            this.addMessageToList();
        } else if (this.props.appState === this.props.States.Authenticated) {
            this.getConversationMessages();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
        document.removeEventListener("keydown", this.handleKeyDown);
        document.removeEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWIMMESSAGERECEIVED, this.onMessageReceived);
        document.removeEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWIMRECEIPTRECEIVED, this.onMessageReceiptReceived);
        document.removeEventListener(RainbowSDKService.rainbowSDK.bubbles.RAINBOW_ONBUBBLEUPDATED, this.onBubbleUpdated);
        document.removeEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWPARTICIPANTSTATUS, this.onNewParticipantStatus);
    }

    handleKeyDown(event) {
        console.log("Handle key down : ", event.keyCode);
        if (event.keyCode === 13) {
            this.buttonAction();
        }
    }

    onMessageReceived(event) {
        let message = event.detail.message;
        let conversation = event.detail.conversation;

        if (conversation && conversation.room) {
            console.log("[WelcomeChat] Receive a message : ", message);
            RainbowSDKService.markMessageFromConversationAsRead(conversation, message);
            this.addRainbowMessageToList(message);
        }
    }

    onMessageReceiptReceived(event) {
        if (event.detail.evt === "server") {
            console.log("[WelcomeChat] messages : ", event.detail.message);
            this.addRainbowMessageToList(event.detail.message);
        }
    }

    onNewParticipantStatus(event) {
        let status = event.detail.status;

        if (status === "composing") {
            this.setState({"typing": true});
        } else if (status === "active"){
            this.setState({"typing": false});
        }
    }

    onBubbleUpdated(event){
        let bubble = event.detail;
        console.log("[WelcomeChat] onBubbleUpdated", bubble.status );
        if (bubble.status === "unsubscribed"){
            console.log(this.props.appState);
            if (this.props.appState === this.props.States.Initialised) {
                this.props.setAppState(this.props.States.Expired);
            } else if (this.props.appState === this.props.States.Authenticated){
                this.props.setAppState(this.props.States.ChatArchived);
            }
        }
    };

    getConversationMessages() {
        if (!this.conversationRetrieved) {
            this.conversationRetrieved = true;
            RainbowSDKService.openConversationForBubble(this.props.room).then(conversation => {
                RainbowSDKService.getMessagesFromConversation(conversation).then(conversationWithHistory => {
                    let filteredMessages = conversation.messages.filter(message => {
                        return this.isMessageToAdd(message);
                    })
                    if (filteredMessages.length > 0) {
                        this.setState({"messages": this.deduplicateMessageList(this.state.messages.concat(filteredMessages))});
                    }
                });
            }).catch( e => {
                console.log("[WelcomeChat] Got error while retrieving messages from bubble : ", e);
                this.props.setAppState(this.props.States.Error);
            });
        }
    }

    deduplicateMessageList = (messageList) => {
        let deduplictedMessages = []
        for (let message of messageList) {
            let alreadyExist = deduplictedMessages.find((existingMessage) => {
                return existingMessage.id === message.id;
            });
            if (!alreadyExist) {
                deduplictedMessages.push(message);
            }
        }
        return deduplictedMessages;
    }

    addRainbowMessageToList = (message) => {
        if(this.isMessageToAdd(message)) {
            let alreadyExist = this.state.messages.find((existingMessage) => {
                return existingMessage.id === message.id;
            });
            if (!alreadyExist) {
                this.setState({"messages": this.deduplicateMessageList(this.state.messages.concat(message))});
                this.scrollToBottom();
            }
        }
    }

    isMessageToAdd(message) {
        let isToAdd = (message.side !== "ADMIN");
        if (isToAdd && message.side === "L") {
            Config.chatService.hiddenMessagePrefix.forEach(prefix => {
                if (message.data.startsWith(prefix) || message.isMarkdown) {
                    isToAdd = false;
                }
            });
        }
        return isToAdd;
    }

    handleMessageChange(event) {
        // This part of code enable you to enter text during the formular phase
        console.log(event.target.value);
        if (["rainbowOffice", "rainbowOfficeSupport"].includes(this.chatFlow) && this.stepsCursor === "7") {
            this.flowData.companyName = event.target.value;
        }
    }

    unavailableMessage(text, link_text) {
        let messages = text.split("%s");
        // We have to use a button to generate the url, the link will depend of the user chat reason during runtime
        messages.splice(1,0, <button className="link-button" onClick={this.redirectToContactUs}>{link_text}</button>)
        // We use React.createElement.apply, to unpack the array without using a map
        return (
            <>
                {React.createElement.apply(null, ['div', {}, ...messages])}
            </>
        );
    }

    initSteps() {
        let supportedCountries = I18nService.getSupportedCountriesForSelect();
        supportedCountries.push({ value: "otherCountry", label: I18n.translate("other_country")});
        const allCountries = I18nService.getAllCountriesForSelect();

        this.steps = {
            regular : {
                "1" : {
                    id: 1,
                    fileId: null,
                    data: I18n.translate("welcome_message"),
                    side: "L",
                    trigger: "2"
                },
                "2" : {
                    id: 2,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="reasonForChat" className="form">
                                <label>
                                    {I18n.translate("welcome_message_reason")}
                                </label><br/>
                                <label className="flex-label">
                                    <input name="reasonForChat" type="radio" value="isSalesRequestRelated" onChange={this.handleReasonForChatChange} />
                                    {I18n.translate("form_choice_sales_request")}
                                </label>
                                <label className="flex-label">
                                    <input name="reasonForChat" type="radio" value="isSolutionAndProductRelated" onChange={this.handleReasonForChatChange} />
                                    {I18n.translate("form_choice_solution_and_products_information")}
                                </label>
                                <label className="flex-label">
                                    <input name="reasonForChat" type="radio" value="isOtherInquiry" onChange={this.handleReasonForChatChange} />
                                    {I18n.translate("form_choice_other")}
                                </label>
                            </form>
                        </div>
                    ),
                    actionRequired: this.validateReasonForChat,
                    side: "L",
                    customType: "form",
                    trigger: {
                        success: "3",
                        failure: "30",
                        other: "5"
                    },
                },
                "3" : {
                    id: 3,
                    fileId: null,
                    data: I18n.translate("agent_available_first_message"),
                    side: "L",
                    trigger: "4"
                },
                "4" : {
                    id: 4,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="regularPersonalInformation" className="form">
                                <label>
                                    <input name="firstName" type="text" onChange={this.handlePersonalInformation} placeholder={I18n.translate("first_name")} />
                                </label><br/>
                                <label>
                                    <input name="lastName" type="text" onChange={this.handlePersonalInformation} placeholder={I18n.translate("last_name")}/>
                                </label><br/>
                                <label>
                                    <input name="email" type="text" onChange={this.handlePersonalInformation} placeholder={I18n.translate("email")}/>
                                </label><br/>
                            </form>
                        </div>
                    ),
                    actionRequired: this.validateRegularPersonalInformation,
                    side: "L",
                    customType: "form",
                    end: true
                },
                "5" : {
                    id: 5,
                    fileId: null,
                    data: parse(I18n.translate("agent_available_other_language"), I18nService.getNativeLanguageName(I18nService.getUsedLanguage())),
                    side: "L",
                    trigger: "6"
                },
                "6" : {
                    id: 6,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="englishChatRedirectionForm" className="form">
                                <label>
                                    <input name="englishChatRedirection" type="radio" value="acceptEnglishChat" onChange={this.handleChangeEnglishChatProposal} />
                                    {I18n.translate("accept_english_chat")}
                                </label><br/>
                                <label>
                                    <input name="englishChatRedirection" type="radio" value="declineEnglishChat" onChange={this.handleChangeEnglishChatProposal} />
                                    {I18n.translate("decline_english_chat")}
                                </label><br/>
                            </form>
                        </div>
                    ),
                    actionRequired: this.validateEnglishChat,
                    side: "L",
                    customType: "form",
                    trigger : {
                        success: "3",
                        failure: "29"
                    },
                },
                "29" : {
                    id: 29,
                    fileId: null,
                    data: this.unavailableMessage(I18n.translate("unavailable_agent_decline_english_chat"), I18n.translate("unavailable_agent_decline_link_text")),
                    side: "L",
                    actionRequired: this.stillUnavailableMessage,
                    trigger: {
                        success: "30"
                    }
                },
                "30" : {
                    id: 30,
                    fileId: null,
                    data: this.unavailableMessage(I18n.translate("unavailable_agent_first_message"), I18n.translate("unavailable_agent_link_text")),
                    side: "L",
                    actionRequired: this.stillUnavailableMessage,
                    trigger: {
                        success: "30"
                    }
                }
            },
            rainbowOffice : {
                "1" : {
                    id: 1,
                    fileId: null,
                    data: I18n.translate("welcome_message"),
                    side: "L",
                    trigger: "2"
                },
                "2" : {
                    id: 2,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="reasonForChat" className="form">
                                <label>
                                    {I18n.translate("welcome_message_reason")}
                                </label><br/>
                                <label className="flex-label">
                                    <input name="reasonForChat" type="radio" value="isOtherInquiry" onChange={this.handleReasonForChatChange} />
                                    {I18n.translate("form_choice_other_not_rbo")}
                                </label>
                            </form>
                        </div>
                    ),
                    actionRequired: this.validateReasonForChat,
                    customType: "form",
                    side: "L",
                    trigger: {
                        success: 3,
                        failure: 30,
                        other: 10
                    }
                },
                "3" : {
                    id: 3,
                    fileId: null,
                    data: I18n.translate("agent_available_first_message"),
                    side: "L",
                    trigger: "4"
                },
                "4" : {
                    id: 4,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="regularPersonalInformation" className="form">
                                <label>
                                    <input name="firstName" type="text" onChange={this.handlePersonalInformation}
                                           placeholder={I18n.translate("first_name")} />
                                </label><br/>
                                <label>
                                    <input name="lastName" type="text" onChange={this.handlePersonalInformation}
                                           placeholder={I18n.translate("last_name")}/>
                                </label><br/>
                                <label>
                                    <input name="email" type="text" onChange={this.handlePersonalInformation}
                                           placeholder={I18n.translate("email")}/>
                                </label><br/>
                                <label>
                                    <Select placeholder={I18n.translate("country")}
                                            options={supportedCountries}
                                            onChange={this.handleSelectCountryChange}
                                            onMenuOpen={() => this.scrollToBottom(true)}/>
                                </label>
                            </form>
                        </div>
                    ),
                    actionRequired: this.validateRainbowOfficeRegularForm,
                    side: "L",
                    customType: "form",
                    trigger: {
                        success: "5",
                        failure: "8"
                    }
                },
                "5" : {
                    id: 5,
                    fileId: null,
                    data: I18n.translate("ask_end_user_or_business"),
                    side: "L",
                    trigger: "6"
                },
                "6" : {
                    id: 6,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="userType" className="form">
                                <label className="flex-label">
                                    <input name="userType" type="radio" value="Customer" onChange={this.handleRainbowOfficeUserTypeChange} />
                                    {I18n.translate("customer")}
                                </label>
                                <label className="flex-label">
                                    <input name="userType" type="radio" value="BusinessPartner" onChange={this.handleRainbowOfficeUserTypeChange} />
                                    {I18n.translate("business_partner")}
                                </label>
                                <label className="flex-label">
                                    <input name="userType" type="radio" value="NeitherCustomerOrBusinessPartner" onChange={this.handleRainbowOfficeUserTypeChange} />
                                    {I18n.translate("neither_different_question")}
                                </label>
                            </form>
                        </div>
                    ),
                    actionRequired: this.validateUserType,
                    side: "L",
                    customType: "form",
                    trigger: {
                        success: "7"
                    }
                },
                "7" : {
                    id: 7,
                    fileId: null,
                    data: I18n.translate("ask_company_name"),
                    side: "L",
                    actionRequired: this.validateCompanyName,
                    end: true
                },
                "8" : {
                    id: 8,
                    fileId: null,
                    data: I18n.translate("pick_your_country"),
                    side: "L",
                    trigger: "9"
                },
                "9" : {
                    id: 4,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="otherCountryForm" className="form">
                                <label>
                                    <Select placeholder={I18n.translate("country")}
                                            options={allCountries} onChange={this.handleSelectCountryChange}
                                            onMenuOpen={this.scrollToBottom}/>
                                </label>
                            </form>
                        </div>
                    ),
                    side: "L",
                    actionRequired: this.validateOtherCountryChoice,
                    customType: "form",
                    end: true
                },
                "10" : {
                    id: 10,
                    fileId: null,
                    data: parse(I18n.translate("agent_available_other_language"), I18nService.getNativeLanguageName(I18nService.getUsedLanguage())),
                    side: "L",
                    trigger: "11"
                },
                "11" : {
                    id: 11,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="englishChatRedirectionForm" className="form">
                                <label>
                                    <input name="englishChatRedirection" type="radio" value="acceptEnglishChat" onChange={this.handleChangeEnglishChatProposal} />
                                    {I18n.translate("accept_english_chat")}
                                </label><br/>
                                <label>
                                    <input name="englishChatRedirection" type="radio" value="declineEnglishChat" onChange={this.handleChangeEnglishChatProposal} />
                                    {I18n.translate("decline_english_chat")}
                                </label><br/>
                            </form>
                        </div>
                    ),
                    actionRequired: this.validateEnglishChat,
                    side: "L",
                    customType: "form",
                    trigger : {
                        success: "3",
                        failure: "29"
                    },
                },
                "29" : {
                    id: 29,
                    fileId: null,
                    data: this.unavailableMessage(I18n.translate("unavailable_agent_decline_english_chat"), I18n.translate("unavailable_agent_decline_link_text")),
                    side: "L",
                    actionRequired: this.stillUnavailableMessage,
                    trigger: {
                        success: "30"
                    }
                },
                "30" : {
                    id: 30,
                    fileId: null,
                    data: this.unavailableMessage(I18n.translate("unavailable_agent_first_message"), I18n.translate("unavailable_agent_link_text")),
                    side: "L",
                    actionRequired: this.stillUnavailableMessage,
                    trigger: {
                        success: "30"
                    }
                }
            },
            rainbowOfficeSupport : {
                "1" : {
                    id: 1,
                    fileId: null,
                    data: I18n.translate("welcome_message"),
                    side: "L",
                    trigger: "2"
                },
                "2" : {
                    id: 2,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="reasonForChat" className="form">
                                <label>
                                    {I18n.translate("welcome_message_reason")}
                                </label><br/>
                                <label className="flex-label">
                                    <input name="reasonForChat" type="radio" value="isOtherInquiry" onChange={this.handleReasonForChatChange} />
                                    {I18n.translate("form_choice_other")}
                                </label>
                            </form>
                        </div>
                    ),
                    actionRequired: this.validateReasonForChat,
                    customType: "form",
                    side: "L",
                    trigger: {
                        success: 3,
                        failure: 30,
                        other: 10
                    }
                },
                "3" : {
                    id: 3,
                    fileId: null,
                    data: I18n.translate("agent_available_first_message"),
                    side: "L",
                    trigger: "4"
                },
                "4" : {
                    id: 4,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="regularPersonalInformation" className="form">
                                <label>
                                    <input name="firstName" type="text" onChange={this.handlePersonalInformation}
                                           placeholder={I18n.translate("first_name")} />
                                </label><br/>
                                <label>
                                    <input name="lastName" type="text" onChange={this.handlePersonalInformation}
                                           placeholder={I18n.translate("last_name")}/>
                                </label><br/>
                                <label>
                                    <input name="email" type="text" onChange={this.handlePersonalInformation}
                                           placeholder={I18n.translate("email")}/>
                                </label><br/>
                                <label>
                                    <Select placeholder={I18n.translate("country")}
                                            options={supportedCountries}
                                            onChange={this.handleSelectCountryChange}
                                            onMenuOpen={() => this.scrollToBottom(true)}/>
                                </label>
                            </form>
                        </div>
                    ),
                    actionRequired: this.validateRainbowOfficeRegularForm,
                    side: "L",
                    customType: "form",
                    trigger: {
                        success: "5",
                        failure: "8"
                    }
                },
                "5" : {
                    id: 5,
                    fileId: null,
                    data: I18n.translate("ask_end_user_or_business"),
                    side: "L",
                    trigger: "6"
                },
                "6" : {
                    id: 6,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="userType" className="form">
                                <label className="flex-label">
                                    <input name="userType" type="radio" value="Customer" onChange={this.handleRainbowOfficeUserTypeChange} />
                                    {I18n.translate("customer")}
                                </label>
                                <label className="flex-label">
                                    <input name="userType" type="radio" value="BusinessPartner" onChange={this.handleRainbowOfficeUserTypeChange} />
                                    {I18n.translate("business_partner")}
                                </label>
                                <label className="flex-label">
                                    <input name="userType" type="radio" value="NeitherCustomerOrBusinessPartner" onChange={this.handleRainbowOfficeUserTypeChange} />
                                    {I18n.translate("neither_different_question")}
                                </label>
                            </form>
                        </div>
                    ),
                    actionRequired: this.validateUserType,
                    side: "L",
                    customType: "form",
                    trigger: {
                        success: "7"
                    }
                },
                "7" : {
                    id: 7,
                    fileId: null,
                    data: I18n.translate("ask_company_name"),
                    side: "L",
                    actionRequired: this.validateCompanyName,
                    end: true
                },
                "8" : {
                    id: 8,
                    fileId: null,
                    data: I18n.translate("pick_your_country"),
                    side: "L",
                    trigger: "9"
                },
                "9" : {
                    id: 4,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="otherCountryForm" className="form">
                                <label>
                                    <Select placeholder={I18n.translate("country")}
                                            options={allCountries} onChange={this.handleSelectCountryChange}
                                            onMenuOpen={this.scrollToBottom}/>
                                </label>
                            </form>
                        </div>
                    ),
                    side: "L",
                    actionRequired: this.validateOtherCountryChoice,
                    customType: "form",
                    end: true
                },
                "10" : {
                    id: 10,
                    fileId: null,
                    data: parse(I18n.translate("agent_available_other_language"), I18nService.getNativeLanguageName(I18nService.getUsedLanguage())),
                    side: "L",
                    trigger: "11"
                },
                "11" : {
                    id: 11,
                    fileId: null,
                    data: (
                        <div className="formContent">
                            <form id="englishChatRedirectionForm" className="form">
                                <label>
                                    <input name="englishChatRedirection" type="radio" value="acceptEnglishChat" onChange={this.handleChangeEnglishChatProposal} />
                                    {I18n.translate("accept_english_chat")}
                                </label><br/>
                                <label>
                                    <input name="englishChatRedirection" type="radio" value="declineEnglishChat" onChange={this.handleChangeEnglishChatProposal} />
                                    {I18n.translate("decline_english_chat")}
                                </label><br/>
                            </form>
                        </div>
                    ),
                    actionRequired: this.validateEnglishChat,
                    side: "L",
                    customType: "form",
                    trigger : {
                        success: "3",
                        failure: "29"
                    },
                },
                "29" : {
                    id: 29,
                    fileId: null,
                    data: this.unavailableMessage(I18n.translate("unavailable_agent_decline_english_chat"), I18n.translate("unavailable_agent_decline_link_text")),
                    side: "L",
                    actionRequired: this.stillUnavailableMessage,
                    trigger: {
                        success: "30"
                    }
                },
                "30" : {
                    id: 30,
                    fileId: null,
                    data: this.unavailableMessage(I18n.translate("unavailable_support_agent"), I18n.translate("unavailable_support_agent_link_text")),
                    side: "L",
                    actionRequired: this.stillUnavailableMessage,
                    trigger: {
                        success: "30"
                    }
                }
            }
        }
    }

    async isTyping() {
        this.setState({"typing" : true});
        await new Promise(res => setTimeout(res, 700));
        this.setState({"typing" : false});
        await new Promise(res => setTimeout(res, 300));
    }

    disableForm = (id) => {
        let elements = document.getElementById(id);
        let len=elements.length;
        for(let i=0; i<len; i++) {
            elements[i].disabled=true;
        }
    }

    checkAvailability(language="en", reason=null) {
        // TODO MOVE THIS IN SERVICE
        return new Promise((resolve, reject) => {
            fetch(Config.chatService.url + "/availability?language="+language+"&reason="+reason, {
                method: "get",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(jsonResponse => {
                        resolve(jsonResponse)
                    }).catch(err => {
                        console.log(err);
                        reject(err);
                    });
                } else {
                    reject(response);
                }
            }).catch(err => {
                console.log("[WelcomeChat] Error trying to find available agents => ", err);
                reject(err);
            })
        });
    }

    redirectToContactUs() {
        const url = this.state.chatUnavailabilityRedirection[this.flowData.reasonForChat];
        let elementA = document.createElement("a");
        document.body.appendChild(elementA);
        elementA.style.display = "none";
        elementA.target = "_blank";
        elementA.href = url;
        elementA.rel = "noopener noreferrer";
        elementA.click();
        window.URL.revokeObjectURL(url);
    }

    addMessageToList = () => {
        // Get all the messages until one that require an action, starting at this.steps[this.stepsCursor]
        let messagesToAdd = [];
        let keepAdding = true;

        while (keepAdding) {
            let tmpMessage = this.steps[this.chatFlow][this.stepsCursor];
            messagesToAdd.push(tmpMessage);
            if (tmpMessage.actionRequired) {
                keepAdding = false;
                this.buttonAction = tmpMessage.actionRequired;
            } else {
                this.stepsCursor = tmpMessage.trigger;
            }
        }
        this.setState({"messages": this.deduplicateMessageList(this.state.messages.concat(messagesToAdd))});
        this.scrollToBottom();
    }

    async validateEnglishChat() {
        if (this.acceptEnglishChat === undefined) {
            console.log("[WelcomeChat] You have to accept or decline an english chat redirection");
            return;
        }

        this.buttonAction = () => {};
        this.disableForm("englishChatRedirectionForm");

        if (!this.acceptEnglishChat) {
            await this.isTyping();
            this.stepsCursor = this.steps[this.chatFlow][this.stepsCursor].trigger.failure;
            this.props.deleteSession("refused_chat", this.flowData.reasonForChat, this.flowData.language);
            this.addMessageToList();
        }
        else {
            await this.isTyping();

            this.flowData.language = "en";
            this.stepsCursor = this.steps[this.chatFlow][this.stepsCursor].trigger.success;
            this.addMessageToList();
        }
    }

    handleChangeEnglishChatProposal(event) {
        this.acceptEnglishChat = event.target.value === "acceptEnglishChat";
        this.validateEnglishChat();
    }

    async validateReasonForChat() {
        if (this.flowData.reasonForChat === "") {
            return;
        }

        this.buttonAction = () => {};
        this.disableForm("reasonForChat");

        // Check Agent Availability for the chat request
        try {
            await this.checkAvailability(I18nService.getUsedLanguage(), this.flowData.reasonForChat);
            this.props.initiateChatSession(this.flowData.language, this.flowData.reasonForChat);
            await this.isTyping();

            this.stepsCursor = this.steps[this.chatFlow][this.stepsCursor].trigger.success;
            console.log("[WelcomeChat] Continue with flow : ", this.chatFlow, " at trigger : ", this.stepsCursor);
            this.addMessageToList();
        } catch (e) {
            // Error or no agent available in user language
            if (I18nService.getUsedLanguage() !== "en") {
                try {
                    await this.checkAvailability("en", this.flowData.reasonForChat);
                    this.props.initiateChatSession("en", this.flowData.reasonForChat);
                    await this.isTyping();

                    this.stepsCursor = this.steps[this.chatFlow][this.stepsCursor].trigger.other;
                    console.log("[WelcomeChat] Continue with flow : ", this.chatFlow, " at trigger : ", this.stepsCursor);
                    this.addMessageToList();
                } catch (e) {
                    this.stepsCursor = this.steps[this.chatFlow][this.stepsCursor].trigger.failure;
                    console.log("[WelcomeChat] Continue with flow : ", this.chatFlow, " at trigger : ", this.stepsCursor);
                    this.addMessageToList();
                }
            } else {
                this.stepsCursor = this.steps[this.chatFlow][this.stepsCursor].trigger.failure;
                console.log("[WelcomeChat] Continue with flow : ", this.chatFlow, " at trigger : ", this.stepsCursor);
                this.addMessageToList();
            }
        }
    }

    handlePersonalInformation(event) {
        console.log(event.target.name);
        this.flowData[event.target.name] = event.target.value;
    }

    async validateRegularPersonalInformation() {
        if (this.flowData.email && this.flowData.firstName && this.flowData.lastName) {
            this.buttonAction = () => {};
            this.disableForm("regularPersonalInformation");
            document.removeEventListener("keydown", this.handleKeyDown);
            await this.isTyping();
            console.log(this.flowData);
            await this.props.joinChatSession(this.flowData);
            this.setState({"typing": true});
            this.getConversationMessages();
        }
    }

    async validateRainbowOfficeRegularForm() {
        if (this.flowData.email && this.flowData.firstName && this.flowData.lastName && this.flowData.country) {
            this.buttonAction = () => {};
            this.disableForm("regularPersonalInformation");
            await this.isTyping();
            console.log(this.flowData);
            if (this.flowData.country === "otherCountry") {
                this.stepsCursor = this.steps[this.chatFlow][this.stepsCursor].trigger.failure;
            } else {
                this.stepsCursor = this.steps[this.chatFlow][this.stepsCursor].trigger.success;
            }
            this.addMessageToList();
        }
    }

    async validateOtherCountryChoice() {
        if (this.flowData.country !== "") {
            this.buttonAction = () => {};
            this.disableForm("otherCountryForm");
            await this.isTyping();
            await this.props.joinChatSession(this.flowData);
            this.setState({"typing": true});
            this.getConversationMessages();
        }
    }

    async stillUnavailableMessage() {
        await this.isTyping();
        this.stepsCursor = this.steps[this.chatFlow][this.stepsCursor].trigger.success;
        this.addMessageToList();
    }

    scrollToBottom (fromSelect = false, timeout = 100) {
        if (!fromSelect && this.stepsCursor === "4" && (this.props.appState === this.props.States.Initialised)) {
            document.getElementById("message3").scrollIntoView();
            return;
        }
        // We scroll after a short delay, to be sure our component is rendered
        setTimeout(() => {
            let target = document.getElementById("chat");
            target.scrollTop = target.scrollHeight;
        },timeout);
    }

    handleReasonForChatChange(event) {
        console.log(event.target.value);
        this.flowData.reasonForChat = event.target.value;
        this.chatFlow = this.flowAssociateToReasonForChat[this.flowData.reasonForChat];
        this.validateReasonForChat();
    }

    handleChange(event) {
        console.log(event.target.value);
        this.setState({"reasonForChat": event.target.value});
    }

    handleSelectCountryChange(selectedOption) {
        this.flowData.country = selectedOption.value;
    }

    handleRainbowOfficeUserTypeChange(event) {
        this.flowData.customerType = event.target.value;
        this.validateUserType();
    }

    async validateUserType() {
        if (this.flowData.customerType) {
            this.buttonAction = () => {};
            this.disableForm("userType");
            await this.isTyping();
            this.setState({"disabled": false});
            this.stepsCursor = this.steps[this.chatFlow][this.stepsCursor].trigger.success;
            this.addMessageToList();
        }
    }

    async validateCompanyName() {
        if (this.flowData.companyName) {
            this.buttonAction = () => {};
            document.getElementById("message-to-send").value = "";
            document.removeEventListener("keydown", this.handleKeyDown);
            this.setState({"messages": this.state.messages.concat(
                {
                    id: 78,
                    fileId: null,
                    data: this.flowData.companyName,
                    side: "R"
                })});
            this.scrollToBottom();
            await this.isTyping();
            await this.props.joinChatSession(this.flowData);
            this.setState({"typing": true});
            this.getConversationMessages();
        }
    }

    onResize() {
        console.log("[WelcomeChat] Window resized, forced to refresh for height calculation");
        this.forceUpdate();
    }

    render() {
        let messagesList = [];

        if (this.state.messages) {
            messagesList = this.state.messages.map((message, key) => {
                return <Message message={message} key={key}/>
            });
        }

        return (
            <>
                <div id="chat" className="chat-area">
                    <div id="chat-listOfMessages" className="chat-messages">
                        {messagesList}
                    </div>
                    { (this.stepsCursor === "4" && (this.props.appState === this.props.States.Initialised)) && <LegalText/> }
                </div>
                { this.state.typing && <Typing /> }

                { this.props.appState === this.props.States.Initialised &&
                    <SendMessageBar bubble={this.props.room}
                                disabled={this.state.disabled}
                                handleMessageChange={this.handleMessageChange}
                                buttonAction={() => this.buttonAction()}
                    />
                }
                { this.props.appState === this.props.States.Authenticated &&
                    <SendMessageBar bubble={this.props.room} />
                }
                { this.props.appState === this.props.States.ChatArchived &&
                    <Information message={I18n.translate("chat_conversation_closed_message")}/>
                }
                { this.props.appState === this.props.States.Expired &&
                    <Information message={I18n.translate("chat_expired")}/>
                }
                { this.props.appState === this.props.States.Error &&
                    <Information message={I18n.translate("unknown_error")}/>
                }
            </>
        )
    }
}
