import React from "react";
import "./Message.css";
import RainbowSDKService from "../services/RainbowSDK/RainbowSDKService";
import headset from "../img/headset.svg";
import I18n from "i18n-react";

export default class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "message": this.props.message
        };
    }

    saveToFile(blob, fileName) {
        let url = window.URL.createObjectURL(blob);
        let elementA = document.createElement("a");
        document.body.appendChild(elementA);
        elementA.style.display = "none";
        elementA.href = url;
        elementA.download = fileName;
        elementA.click();
        window.URL.revokeObjectURL(url);
    }

    fileDownload(fileId) {
        console.log("[Message] Got file with id : ", fileId);
        let fileDescriptor = RainbowSDKService.getFileDescriptor(fileId);
        RainbowSDKService.downloadFile(fileDescriptor).then( blob => {
            this.saveToFile(blob, fileDescriptor.fileName);
            console.log("[Message] File with id : ", fileId, " downloaded successfully");
        }).catch( err => {
            console.log("[Message] Unable to download the file with id : ", fileId, " err: ", err);
        })
    }

    render () {
        let id = "message" + this.state.message.id;
        let content = this.state.message.data;
        let side = this.state.message.side;
        let fileId = this.state.message.fileId;
        let date = this.state.message.date? new Date(this.state.message.date).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}) : undefined;
        let customType = this.state.message.customType? "-" + this.state.message.customType : "";
        let sideClassName = "chat-message-" + side + customType + " message-content";

        return (
            <div className="chat-message-container">
                <div id={id} className="message">
                    {
                        (side === 'L' && customType === "") &&
                            <div className="icon-agent-container">
                                <img className="icon-agent" src={headset} alt={I18n.translate("alt_code_icon_agent")}/>
                            </div>
                    }
                    <div className={sideClassName}>
                        {
                            fileId !== null
                            ? <button type="button" className="link-button" onClick={this.fileDownload.bind(this, fileId)}>{content}</button>
                            : content
                        }
                        {
                            (date !== undefined) && <div className="message-timestamp">{date}</div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
