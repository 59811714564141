import rainbowSDK from "rainbow-web-sdk";
import Config from "../../config/config.json";


class RainbowSDKService {
    start() {
        console.log("[RainbowSDKService] starting");
        this.rainbowSDK = rainbowSDK;
        /* Add window object to allow debugging from the console */
        window.rainbowSDK = rainbowSDK;

        return new Promise((resolve, reject) => {
            let onReady = () => {
                console.log('[RainbowSDKService] Rainbow SDK Ready !');
                resolve();
            }
            let onLoaded = () => {
                console.log('[RainbowSDKService] Rainbow SDK Loaded !');
            }

            document.addEventListener(rainbowSDK.RAINBOW_ONREADY, onReady);
            document.addEventListener(rainbowSDK.RAINBOW_ONREADY, onLoaded);

            rainbowSDK.load();
            rainbowSDK
                .initialize(Config.rainbow.application.appID, Config.rainbow.application.appSecret)
                .then(() => {
                    console.log('[RainbowSDKService] SDK is well initialized');
                })
                .catch(err => {
                    console.log('[RainbowSDKService] Error during initialisation : ', err);
                    reject();
                });
        })
    }

    login(email, password) {
        return new Promise((resolve, reject) => {
            rainbowSDK.connection.signinOnRainbowHosted(email, password, Config.rainbow.server.host).then( account => {
                resolve(account);
            }).catch(error => {
                console.log("[RainbowSDKService] Error login in ", error);
                reject({
                    "data": {
                        "message": "Error Login"
                    }
                });
            })
        })
    }

    loginWithToken(token) {
        return new Promise((resolve, reject) => {
            rainbowSDK.connection.signinOnRainbowHostedWithToken(token, Config.rainbow.server.host).then(account => {
                resolve(account);
            }).catch(error => {
                console.log("[RainbowSDKService] Error login in with token ", error);
                reject({
                    "data": {
                        "message": "Error login"
                    }
                })
            });
        });
    }

    openConversationForBubble(bubble) {
        return rainbowSDK.conversations.openConversationForBubble(bubble);
    }

    getMessagesFromConversation(conversation){
        return rainbowSDK.im.getMessagesFromConversation(conversation);
    }

    markMessageFromConversationAsRead(conversation, message) {
        rainbowSDK.im.markMessageFromConversationAsRead(conversation, message);
    }

    getFileDescriptor(fileId) {
        return rainbowSDK.fileStorage.getFileDescriptorFromId(fileId);
    }

    downloadFile(fileDescriptor) {
        return rainbowSDK.fileStorage.downloadFile(fileDescriptor);
    }

    sendMessageToBubble(bubble, message) {
        rainbowSDK.im.sendMessageToBubble(bubble, message);
    }
}

export default new RainbowSDKService();
